import React, {Component, useContext, useEffect, useState} from "react";
import { Link, Navigate, NavLink, Outlet, useLocation } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import axios from "axios";
import { baseUrl } from "../Resource/api";


function PublicTopBar(){
  const {token} = useContext(AppContext)
    return(
        <>
        {token !=null && (<Navigate to={'/admin'} replace={true}/>)}

        <header className="flex bg-prc p-3">
            <img src={require('../Resource/logo.png')} className="flex-none h-12"  alt="logo"/>
            <div className="flex-1 ml-16">
                <div className="  h-full flex font-sans ">
                    {/* Selected */}
                    <NavLink to="/"
                        className={({ isActive }) => {
                            return `h-full content-center cursor-pointer flex-none ${isActive ? 'mr-4' : ''}`;
                        }}
                    >
                        {({ isActive }) => (
                            <div className="h-full content-center cursor-pointer flex-none">
                                <div className={`py-1 px-5 rounded-md mb-2 ${isActive ? 'font-bold bg-white text-prc px-8' : 'text-white'}`}>
                                    HOME
                                </div>
                                {isActive && <div className="bg-src h-1 w-full rounded-full"></div>}
                            </div>
                        )}
                    </NavLink>
                    <NavLink to="/result"
                        className={({ isActive }) => {
                            return `h-full content-center cursor-pointer flex-none ${isActive ? 'mr-4' : ''}`;
                        }}
                        >
                        {({ isActive }) => (
                            <div className="h-full content-center cursor-pointer flex-none">
                                <div className={`py-1 px-5 rounded-md mb-2 ${isActive ? 'font-bold bg-white text-prc px-8' : 'text-white'}`}>
                                    RESULT
                                </div>
                                {isActive && <div className="bg-src h-1 w-full rounded-full"></div>}
                            </div>
                        )}
                    </NavLink>
                    <NavLink to="/schedule"
                        className={({ isActive }) => {
                            return `h-full content-center cursor-pointer flex-none ${isActive ? 'mr-4' : ''}`;
                        }}
                        >
                        {({ isActive }) => (
                            <div className="h-full content-center cursor-pointer flex-none">
                                <div className={`py-1 px-5 rounded-md mb-2 ${isActive ? 'font-bold bg-white text-prc px-8' : 'text-white'}`}>
                                    SCHEDULE
                                </div>
                                {isActive && <div className="bg-src h-1 w-full rounded-full"></div>}
                            </div>
                        )}
                    </NavLink>
                    <NavLink to="/report"
                        className={({ isActive }) => {
                            return `h-full content-center cursor-pointer flex-none ${isActive ? 'mr-4' : ''}`;
                        }}
                        >
                        {({ isActive }) => (
                            <div className="h-full content-center cursor-pointer flex-none">
                                <div className={`py-1 px-5 rounded-md mb-2 ${isActive ? 'font-bold bg-white text-prc px-8' : 'text-white'}`}>
                                    REPORT
                                </div>
                                {isActive && <div className="bg-src h-1 w-full rounded-full"></div>}
                            </div>
                        )}
                    </NavLink>
                </div>
            </div>
            <NavLink to="login" className="flex-none self-center">
                <div className=" cursor-pointer bg-src px-8 font-sans font-semibold py-1 rounded-md text-white">
                LOGIN
                </div>
            </NavLink>
        </header>
        <div>
            <Outlet/>
        </div>
        </>
    );
}

export function AdminTopBar(){
  const [topBar, setTobBar] = useState([]);
  const [topBarLocation, setTobBarLocation] = useState('');
  const {token,role} = useContext(AppContext)
  const loc = useLocation().pathname;
  useEffect(() => {
    if(loc.includes('public')){
      setTobBar(['home','result','schedule','report'])
      setTobBarLocation('public')
    }else if (loc.includes('master-file')) {
      if(role != 'Admin'){
        setTobBar(['team'])
      }else{
        setTobBar(['team', 'college','sports'])
      }
      setTobBarLocation('master-file')
    } else if (loc.includes('sched')) {
      setTobBar(['schedule', 'result'])
      setTobBarLocation('sched')
    } else if (loc.includes('account')) {
      setTobBar([])
      setTobBarLocation('account')
    } else if (loc.includes('sum')) {
      setTobBar(['dashboard', 'incident'])
      setTobBarLocation('sum')
    }else {
      setTobBar([])
    } 
    
  }, [loc])

  return(
      <>
      <header className="flex bg-prc p-3">
          <img src={require('../Resource/logo.png')} className="flex-none h-12"  alt="logo"/>
          <div className="flex-1 ml-16">
              <div className="  h-full flex font-sans ">
                  {/* Selected */}
                  {topBar.map((item, index) => (
                    <NavLink to={`${topBarLocation}/${item}`}
                    key={index}
                        className={({ isActive }) => {
                            return `h-full content-center cursor-pointer flex-none ${isActive ? 'mr-4' : ''}`;
                        }}
                    >
                        {({ isActive }) => (
                            <div className="h-full content-center cursor-pointer flex-none">
                                <div className={`py-1 px-5 rounded-md mb-2 ${isActive ? 'font-bold bg-white text-prc px-8' : 'text-white'}`}>
                                    {item.toUpperCase()}
                                </div>
                                {isActive && <div className="bg-src h-1 w-full rounded-full"></div>}
                            </div>
                        )}
                    </NavLink>
                  ))}
              </div>
          </div>
      </header>

      </>
  );
}

export function SideBar(){
    const {setToken,setRole, token, role} = useContext(AppContext)
    const handleLogout = (e) => {
      e.preventDefault()
      const logout = async () =>{
        try {
          const loginResponse = await axios.post(baseUrl.concat('/auth/logout'), {}, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            setToken(localStorage.removeItem('token'))
            setRole(localStorage.removeItem('role'))

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            // navigate('/')
        }
      }
      logout()
    }
    // console.log(token)
    return(
        <>
        {token==null && (<Navigate to={'/login'} replace={true}/>)}
        <div className='flex-none sticky top-0 bg-prc h-screen'>
          <div className="flex flex-col h-full">
            <div className="flex-1">
              <div className="h-full flex flex-col font-sans">
                <NavLink
                  to="public"
                  className={({ isActive }) => {
                    return  `content-center justify-center cursor-pointer flex-none m-3 p-2 ${isActive ? 'bg-white rounded-md' : 'bg-prc'}`;
                  }}
                >
                  {({ isActive }) => (
                    <span className={`icon-[ion--home]  w-7 h-7 mt-1 ${isActive ? 'bg-prc': 'bg-white'}`}></span>
                  )}
                </NavLink>

                <NavLink
                  to="sum"
                  className={({ isActive }) => {
                    return  `content-center justify-center cursor-pointer flex-none mt-0 m-3 p-2 ${isActive ? 'bg-white rounded-md' : 'bg-prc'}`;
                  }}
                >
                  {({ isActive }) => (
                    <span className={`icon-[ri--dashboard-fill]  w-7 h-7 mt-1 ${isActive ? 'bg-prc': 'bg-white'}`}></span>
                  )}
                </NavLink>
                
                <NavLink
                  to="master-file"
                  className={({ isActive }) => {
                    return  `content-center justify-center cursor-pointer flex-none mt-0 m-3 p-2 ${isActive ? 'bg-white rounded-md' : 'bg-prc'}`;
                  }}
                >
                  {({ isActive }) => (
                    <span className={`icon-[fluent--document-folder-24-filled]  w-7 h-7 mt-1 ${isActive ? 'bg-prc': 'bg-white'}`}></span>
                  )}
                </NavLink>
                {role != 'College Representative' && (
                <NavLink
                  to="sched"
                  className={({ isActive }) => {
                    return  `content-center justify-center cursor-pointer flex-none mt-0 m-3 p-2 ${isActive ? 'bg-white rounded-md' : 'bg-prc'}`;
                  }}
                >
                  {({ isActive }) => (
                    <span className={`icon-[bxs--calendar]  w-7 h-7 mt-1 ${isActive ? 'bg-prc': 'bg-white'}`}></span>
                  )}
                </NavLink>
                )}

                {role == 'Admin' && (
                <NavLink
                  to="banner"
                  className={({ isActive }) => {
                    return  `content-center justify-center cursor-pointer flex-none mt-0 m-3 p-2 ${isActive ? 'bg-white rounded-md' : 'bg-prc'}`;
                  }}
                >
                  {({ isActive }) => (
                    <span className={`icon-[bi--file-image-fill]  w-7 h-7 mt-1 ${isActive ? 'bg-prc': 'bg-white'}`}></span>
                  )}
                </NavLink>
                )}              
                {role == 'Admin' && (
                <NavLink
                  to="account"
                  className={({ isActive }) => {
                    return  `content-center justify-center cursor-pointer flex-none mt-0 m-3 p-2 ${isActive ? 'bg-white rounded-md' : 'bg-prc'}`;
                  }}
                >
                  {({ isActive }) => (
                    <span className={`icon-[mdi--book-account]  w-7 h-7 mt-1 ${isActive ? 'bg-prc': 'bg-white'}`}></span>
                  )}
                </NavLink>
                )}
                <div className=" flex-1 "></div>

                <NavLink
                  to="profile"
                  className={({ isActive }) => {
                    return  `content-center justify-center cursor-pointer flex-none mt-0 m-3 p-2 ${isActive ? 'bg-white rounded-md' : 'bg-prc'}`;
                  }}
                >
                  {({ isActive }) => (
                    <span className={`icon-[streamline--user-profile-focus-solid] w-7 h-7 mt-1 ${isActive ? 'bg-prc': 'bg-white'}`}></span>
                  )}
                </NavLink>

                <div className={`content-center justify-center cursor-pointer flex-none mt-0 m-3 p-2 hover:scale-105 `} onClick={handleLogout}>
                    <span className={`icon-[fontisto--power] bg-white w-7 h-7 mt-1`}></span>
                </div>

              </div>
            </div>
          </div>
        </div>

        </>
    );
}


export default PublicTopBar;
