import React, { useEffect, useState } from 'react';
import { IncidentCard } from '../../../Components/Cards';
import { Box, Modal } from '@mui/material';
import { v1Basic } from '../../../Resource/api';
import axios from 'axios';
import Loading from '../../../Components/Loading';

export default function IncidentBoard() {
  const [loading, setLoading] = useState(true);
  const [incident, setIncident] = useState([]);
  
  // States for delete modal
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);

  // States for resolve modal
  const [resolveModalIsOpen, setResolveModalIsOpen] = useState(false);
  const [selectedIncidentToResolve, setSelectedIncidentToResolve] = useState(null);

  const handleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen);
  const handleResolveModal = () => setResolveModalIsOpen(!resolveModalIsOpen);

  const apiIncident = v1Basic.concat('incident');

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.delete(apiIncident.concat(`/${selectedIncident.id}`));
      console.log('Response:', response.data);
      setIncident(prev => prev.filter(inc => inc.id !== selectedIncident.id)); // remove the deleted incident from the list
    } catch (error) {
      console.error(error);
    }

    handleDeleteModal();
  };

  const handleResolveSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedIncident = {
        ...selectedIncidentToResolve,
        lib_incident_status_id: '1', // status id 1 for resolved
        reported_by_id: '1' // status id 1 for resolved
      };

      const response = await axios.put(apiIncident.concat(`/${selectedIncidentToResolve.id}`), updatedIncident);
      console.log('Response:', response.data);
      setIncident(prev =>
        prev.map(inc => (inc.id === selectedIncidentToResolve.id ? { ...inc, lib_incident_status_id: '1' } : inc))
      );
    } catch (error) {
      console.error(error);
    }

    handleResolveModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(apiIncident);
        setIncident(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {/* Delete Modal */}
          <Modal
            open={deleteModalIsOpen}
            onClose={handleDeleteModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
          >
            <Box className="bg-white rounded-lg shadow-lg text-def-t">
              <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>
                Remove Incident
              </h3>
              <div className='p-6'>
                <p id="modal-description" className='mb-4'>
                  Are you sure you want to delete this incident{' '}
                  <span className='font-bold'>{selectedIncident?.title}</span>?
                </p>
                <form onSubmit={handleDeleteSubmit}>
                  <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Remove</button>
                </form>
              </div>
            </Box>
          </Modal>

          {/* Resolve Modal */}
          <Modal
            open={resolveModalIsOpen}
            onClose={handleResolveModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
          >
            <Box className="bg-white rounded-lg shadow-lg text-def-t">
              <h3 id="modal-title" className='font-semibold text-xl text-center bg-src rounded-t-lg text-white py-3'>
                Resolve Incident
              </h3>
              <div className='p-6'>
                <p id="modal-description" className='mb-4'>
                  Are you sure you want to resolve this incident{' '}
                  <span className='font-bold'>{selectedIncidentToResolve?.title}</span>?
                </p>
                <form onSubmit={handleResolveSubmit}>
                  <button type='submit' className='mt-2 bg-src rounded-md py-2 w-full text-white'>Resolve</button>
                </form>
              </div>
            </Box>
          </Modal>

          <div className='text-prc text-2xl font-bold p-4 pb-1 flex content-center'>
            <div className='flex-1 cursor-pointer content-center'>
              Incidents
            </div>
          </div>

          <div className='bg-cont p-4 rounded-md m-1'>
            {incident.map((item, index) => (
              <div key={index} className={`flex ${item.lib_incident_status_id.id === 1 ? 'bg-green-300' : 'bg-white-300'} bg-opacity-15 p-4 pb-3 drop-shadow pt-5 rounded-3xl mb-3 border-y-4 border-prc`}>
                <div className='flex-1'>
                  <IncidentCard data={item} />
                </div>
                <div className='flex-none content-center flex text-white font-bold text-xl'>
                  {item.lib_incident_status_id.id !== 1 && (
                    <div className=' content-center mr-2 bg-red'>
                      <div className='bg-src p-6 rounded-lg cursor-pointer' onClick={() => { setSelectedIncidentToResolve(item); handleResolveModal(); }}>
                        Resolve
                      </div>
                    </div>
                  )}
                  <div className=' content-center'>
                    <div className='bg-prc p-6 rounded-lg cursor-pointer' onClick={() => { setSelectedIncident(item); handleDeleteModal(); }}>
                    Remove
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
