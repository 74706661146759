import React, { useState } from 'react';
import Loading from '../../Components/Loading';
import axios from 'axios';
import { v1Basic } from '../../Resource/api';
import { useForm } from 'react-hook-form';

export default function ReportPage() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      setSuccess(false); 
      const response = await axios.post(v1Basic.concat('incident'), {
        ...data,
        lib_incident_status_id: 2,
        reported_by_id: 1
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // console.log(response);
      setSuccess(true);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (<Loading />) : (
        <div
          className='h-screen w-full bg-cover bg-center flex items-center justify-center'
          style={{ backgroundImage: `url(${require('../../Resource/bg.png')})` }}
        >
          <form className='' onSubmit={handleSubmit(onSubmit)}>
            <div className="bg-white p-5 rounded-md drop-shadow text-2xl text-prc font-bold">
              Report Incident
              <div className='font-normal mt-2 text-left text-lg'>
                Would you like to report an incident in your area?
              </div>

              {success && (
                <div className="text-green-500 bg-green-400 p-2 rounded-md border-l-4 bg-opacity-30 border-green-600  text-base font-normal mb-3">
                  Report submitted successfully!
                </div>
              )}

              {/* Title Input */}
              <div className='mt-2 text-left text-base font-semibold'>Title</div>
              <input
                type='text'
                name='title'
                {...register('title', { required: 'Title is required' })}
                placeholder='Incident Title Ex. Car Accident'
                className='font-normal text-base border-2 text-black border-prc rounded-md border-opacity-50 w-full py-2 px-4'
              />
              {errors.title && <p className="text-red-500 text-sm">{errors.title.message}</p>}

              {/* Description Input */}
              <div className='mt-4 text-left text-base font-semibold'>Desc</div>
              <textarea
                name='desc'
                rows={4}
                {...register('desc', { required: 'Description is required' })}
                placeholder='Please describe the Incident and include the place of incident.'
                className='font-normal text-base border-2 text-black border-prc rounded-md border-opacity-50 w-full py-2 px-4'
              />
              {errors.desc && <p className="text-red-500 text-sm">{errors.desc.message}</p>}

              {/* Submit Button */}
              <button type='submit' className='text-base font-normal text-white bg-prc w-full py-2 rounded-md mt-2'>
                Submit Report
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
