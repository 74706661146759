import axios from 'axios';
import React, { useState,useEffect, useContext } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { v1Basic } from '../../../Resource/api';
import { Box, Modal } from '@mui/material';
import { AppContext } from '../../../Context/AppContext';

export default function Team() {
    const {teamID} = useParams();
    const {token} = useContext(AppContext)
    const apiPlayer = v1Basic.concat("player");
    const [team, setTeam] = useState ({
        id: null,
        title: '',
        logo: null,
        sports_id: {
          id: null,
          title: '',
          logo: null,
          venue: ''
        },
        college_id: {
          id: null,
          acronym: '',
          title: '',
          logo: null
        },
        coach_id: {
          first_name: '',
          last_name: '',
          middle_name: '',
          phone_no: '',
          lib_gender_id: {
            id: null,
            desc: ''
          }
        },
        lib_award_id: {
          id: null,
          desc: ''
        },
        players: []
    })
    const [sports, setSports] = useState([])

    const [playerForm, setPlayerFrom] =useState({
        player: {
            student_no: '',
            team_id: teamID,
            position_id: '',
            basic_information_id: "0",
            lib_player_status_id: '1'
        },
        info:{
            first_name: '',
            middle_name: '',
            last_name: '',
            phone_no: '',
            lib_gender_id: '',

        }
    })

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleModal = () => setModalIsOpen(!modalIsOpen)
    const handleChange = (e) => {
        const {name, value} = e.target
        if(name === "position_id" ||name === "student_no"){
            setPlayerFrom((prevForm) =>({
                ...prevForm,
                player:{
                    ...prevForm.player,
                    [name]: value
                }
            }))
        }else {
            setPlayerFrom((prevForm) =>({
                ...prevForm,
                info:{
                    ...prevForm.info,
                    [name]: value
                }
            }))
        }
        
        // console.log(`${name}: ${value}`)
        // console.log(playerForm)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{
            const response = await axios.post(apiPlayer, playerForm, {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
        }catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error Response Data:', error.response.data);
                console.error('Error Response Status:', error.response.status);
                console.error('Error Response Headers:', error.response.headers);
                console.log(playerForm)
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error Request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error Message:', error.message);
            }
            
        }
        handleModal()
    }
    const api = v1Basic.concat(`team/${teamID}`);
    useEffect (() => {
        axios.get(api).then((res) => (
            setTeam(res.data.data)
        ))
         
    }, [])
    const position = sports?.position || []
    useEffect (() => {  
        team.sports_id.id ? (
            axios.get(v1Basic.concat(`sports/${team.sports_id.id}`)).then((res) => (
                setSports(res.data.data)
            ))
        ) : (
            console.log("")  
        )
    }, [team])
    console.log(team.players)
    return (
        <>
        {token==null && (<Navigate to={'/login'} replace={true}/>)}

        <Modal open={modalIsOpen} onClose={handleModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
            <h3 id="modal-title" className='font-semibold text-xl'>New Player</h3>
            <p id="modal-description " className='mb-4'>This Popup is for adding a new Player.</p>
            <form onSubmit={handleSubmit}>

                <div className='  flex'>
                    <div className=' flex-1 mr-2'>
                        <label className='text-sm'>First Name</label>
                        <input type='text' placeholder='Ex. John'
                                name='first_name' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                    <div className=' flex-1 mr-2'>
                        <label className='text-sm'>Last Name</label>
                        <input type='text' placeholder='Ex. Ramos'
                                name='last_name' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                    <div className=' flex-1'>
                        <label className='text-sm'>Middle Name</label>
                        <input type='text' placeholder='Ex. Cruz'
                                name='middle_name' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                </div>
                <div className='  flex'>
                    <div className='flex-none mr-2'>
                        <label className='text-sm'>Gender</label>
                        <ul className="grid w-auto gap-1 md:grid-cols-2">
                            <li>
                                <input type="radio" id={`gender-male`} name="lib_gender_id" value={1}  className="hidden peer" onClick={handleChange} required />
                                <label htmlFor={`gender-male`} 
                                className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                    <div className="block">
                                        <div className="w-full text-sm font-bold truncate">Male</div>
                                    </div>
                                </label>
                            </li>
                            <li>
                                <input type="radio" id={`gender-female`} name="lib_gender_id" value={2}  className="hidden peer" onClick={handleChange} required />
                                <label htmlFor={`gender-female`} 
                                className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                    <div className="block">
                                        <div className="w-full text-sm font-bold truncate">Female</div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className=' flex-1 mr-2'>
                        <label className='text-sm'>Phone No</label>
                        <input type='number' placeholder='Ex. 09748262912'
                                name='phone_no' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                    <div className=' flex-1 mr-2'>
                        <label className='text-sm'>Student No</label>
                        <input type='number' placeholder='Ex. 2022400572'
                                name='student_no' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                </div>
                {/* pos */}
                <div> 
                    <label className='text-sm'>Select Position</label>
                    <ul className="grid w-full gap-1 md:grid-cols-5">
                    {position.map((item, index) => (
                        <li key={index}>
                            <input type="radio" id={`${item.acronym}-${item.id}`} name="position_id" value={item.id}  className="hidden peer" onClick={handleChange} required />
                            <label htmlFor={`${item.acronym}-${item.id}`} 
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                <div className="block">
                                    <div className="w-full text-sm font-bold truncate">{item.desc}</div>
                                    
                                </div>
                            </label>
                        </li>
                    ))}
                    </ul>
                </div>
                <button type='submit' className='mt-5 bg-prc rounded-md py-2 w-full text-white'>Add Player</button>
            </form>
            </Box>
        </Modal>
        <div className=' m-2 mt-5 flex'>
            <div className=' flex-none p-3 mr-2 bg-cont rounded-lg h-full pb-5'>
                <div>
                <div className=' flex flex-row '>
                        <img src={team.logo != null ? (team.logo):(require('../../../Resource/logo-one.png'))} className=' rounded-full h-24 w-24' alt='img'/>
                    </div>
                </div>
                <div className=' flex-1 content-center mt-5 min-w-64'>
                    <div className='flex flex-col  '>
                        <div className=' flex-1 -mt-1 text-sm'>
                            Team Name
                        </div>
                        <div className=' flex-1 font-bold text-2xl -mt-2'>
                            {team.title}
                        </div>
                    </div>

                    <div className='flex flex-col mt-2 '>
                        <div className=' flex-1 -mt-1 text-sm'>
                            Coach
                        </div>
                        <div className=' flex-1 font-bold text-2xl -mt-2'>
                            {team.coach_id.last_name}, {team.coach_id.first_name} {team.coach_id.middle_name.charAt(0).toUpperCase() + '.'}
                        </div>
                    </div>

                    <div className='flex flex-col mt-2 '>
                        <div className=' flex-1 -mt-1 text-sm'>
                            College
                        </div>
                        <div className=' flex-1 font-bold text-2xl -mt-2'>

                            {team.college_id.title}
                        </div>
                    </div>

                    <div className='flex flex-col mt-2 '>
                        <div className=' flex-1 -mt-1 text-sm'>
                            Sport
                        </div>
                        <div className=' flex-1 font-bold text-2xl -mt-2'>
                            {team.sports_id.title}

                        </div>
                    </div>
                </div>
            </div>
            <div className='flex-1'>
                <div className='bg-cont flex-col flex   rounded-lg'>
                    <div className='flex-1 m-3 mb-8'>
                        <div className='flex'>
                        <div className=' flex-1 '>
                            <div className=' flex'>
                            <input type='text' placeholder="Find Player..."
                                    className='bg-transparent p-2 border-2 rounded-l-md flex-1' />
                            <div className='p-3 flex-none bg-prc rounded-r-md'>
                                <span className='icon-[majesticons--search] bg-white w-5 h-5 mt-2 mx-2'></span>
                            </div>
                            <div className=' px-10 ml-20 text-center content-center flex-none bg-prc text-white rounded-md cursor-pointer' onClick={handleModal}>
                                Add Player
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {!team.players || team.players.length == 0 ? (
                        <div className='w-full h-screen text-prc content-center justify-center text-center font-extralight text-4xl'>No Player</div>
                    ):(
                        <>
                        <table>
                        <thead className='bg-prc text-white text-left'>
                            <tr>
                                <th className='px-6 py-3'>Student No.</th>
                                <th className='px-6 py-3'>Full Name</th>
                                <th className='px-6 py-3'>Gender</th>
                                <th className='px-6 py-3'>Position</th>
                                <th className='px-6 py-3'>Status</th>
                                <th className='px-6 py-3'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {team.players.map((item, index)=>(
                                <PlayerInformation key={index} data={item} team={team} position={position}/>
                            ))}
                            </tbody>
                    </table>
                    </>
                    )}
                </div>
            </div>
        </div>
        </>
    )
}

export function PlayerInformation({data, team, position}) {
    const apiPlayer = v1Basic.concat("player");
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const handleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen)

    const [approveModalIsOpen, setApporveModalIsOpen] = useState(false)
    const handleApporveModal = () => setApporveModalIsOpen(!approveModalIsOpen)
    
    const [playerForm, setPlayerFrom] =useState({
        player: {
            student_no: data.student_no,
            team_id: team.id,
            position_id: data.position_id.id,
            basic_information_id: data.basic_information_id.id,
            lib_player_status_id: data.status.id
        },
        info:{
            first_name: data.basic_information_id.first_name,
            middle_name: data.basic_information_id.middle_name,
            last_name: data.basic_information_id.last_name,
            phone_no: data.basic_information_id.phone_no,
            lib_gender_id: data.basic_information_id.gender.id,

        }
    })
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleModal = () => setModalIsOpen(!modalIsOpen)
    const handleChange = (e) => {
        const {name, value} = e.target
        if(name === "position_id" ||name === "student_no"){
            setPlayerFrom((prevForm) =>({
                ...prevForm,
                player:{
                    ...prevForm.player,
                    [name]: value
                }
            }))
        }else {
            setPlayerFrom((prevForm) =>({
                ...prevForm,
                info:{
                    ...prevForm.info,
                    [name]: value
                }
            }))
        }
        
        console.log(`${name}: ${value}`)
        console.log(playerForm)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{
            const updatePlayer = v1Basic.concat('approve-player')
            const response = await axios.put(updatePlayer.concat(`/${data.id}`), playerForm, {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
        }catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error Response Data:', error.response.data);
                console.error('Error Response Status:', error.response.status);
                console.error('Error Response Headers:', error.response.headers);
                console.log(playerForm)
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error Request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error Message:', error.message);
            }
            
        }
        handleModal()
    }
    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
    
        try{
          const response = await axios.delete(apiPlayer.concat(`/${data.id}`))
          console.log('Response:', response.data);
        } catch(error){
          console.log(error)
        };
        handleDeleteModal()
    }

    const handleApporve = async (e) => {
        e.preventDefault();
    
        setPlayerFrom((prevForm) =>({
            ...prevForm,
            player:{
                ...prevForm.player,
                lib_player_status_id: '2'
            }
        }))
        try{
            const response = await axios.put(apiPlayer.concat(`/${data.id}`), playerForm, {
                headers: {
                'Content-Type': 'application/json'
                }
            })
            console.log('Response:', response);
        } catch(error){
        console.log(error)
        };
        handleApporveModal()
    }
  return (
    <>
    <Modal open={deleteModalIsOpen} onClose={handleDeleteModal}
        aria-labelledby="modal-title" aria-describedby="modal-description"
        className="flex justify-center items-center h-screen"
    >
        <Box className="bg-white rounded-lg shadow-lg text-def-t ">
            <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>Remove Player</h3>
            <div className='p-6'>
                <p id="modal-description " className='mb-4'>Are you sure you want to delete <p className='font-bold'>{data.basic_information_id.last_name}, {data.basic_information_id.first_name} {data.basic_information_id.middle_name.charAt(0).toUpperCase() + '.'}</p></p>
                <form onSubmit={handleDeleteSubmit}>
                    <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Remove</button>
                </form>
            </div>
        </Box>
    </Modal>
    <Modal open={approveModalIsOpen} onClose={handleApporveModal}
        aria-labelledby="modal-title" aria-describedby="modal-description"
        className="flex justify-center items-center h-screen"
    >
        <Box className="bg-white rounded-lg shadow-lg text-def-t ">
            <h3 id="modal-title" className='font-semibold text-xl text-center bg-src rounded-t-lg text-white py-3'>Approve Player</h3>
            <div className='p-6'>
                <p id="modal-description " className='mb-4'>Are you sure you want to Apprrove <p className='font-bold'>{data.basic_information_id.last_name}, {data.basic_information_id.first_name} {data.basic_information_id.middle_name.charAt(0).toUpperCase() + '.'}</p></p>
                <form onSubmit={handleApporve}>
                    <button type='submit' className='mt-2 bg-src rounded-md py-2 w-full text-white'>Approve</button>
                </form>
            </div>
        </Box>
    </Modal>
    <Modal open={modalIsOpen} onClose={handleModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
    >
        <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
        <h3 id="modal-title" className='font-semibold text-xl'>Update Player</h3>
        <p id="modal-description " className='mb-4'>This Popup is for updating Player.</p>
        <form onSubmit={handleSubmit}>

            <div className='  flex'>
                <div className=' flex-1 mr-2'>
                    <label className='text-sm'>First Name</label>
                    <input type='text' placeholder='Ex. John'
                            name='first_name' onChange={handleChange} value={playerForm.info.first_name}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
                <div className=' flex-1 mr-2'>
                    <label className='text-sm'>Last Name</label>
                    <input type='text' placeholder='Ex. Ramos'
                            name='last_name' onChange={handleChange} value={playerForm.info.last_name}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
                <div className=' flex-1'>
                    <label className='text-sm'>Middle Name</label>
                    <input type='text' placeholder='Ex. Cruz'
                            name='middle_name' onChange={handleChange} value={playerForm.info.middle_name}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
            </div>
            <div className='  flex'>
                <div className='flex-none mr-2'>
                    <label className='text-sm'>Gender</label>
                    <ul className="grid w-auto gap-1 md:grid-cols-2">
                        <li>
                            <input type="radio" id={`gender-male`} name="lib_gender_id" value={1}  className="hidden peer" onClick={handleChange} checked={1 == playerForm.info.lib_gender_id} required />
                            <label htmlFor={`gender-male`} 
                            className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                <div className="block">
                                    <div className="w-full text-sm font-bold truncate">Male</div>
                                </div>
                            </label>
                        </li>
                        <li>
                            <input type="radio" id={`gender-female`} name="lib_gender_id" value={2}  className="hidden peer" onClick={handleChange} checked={2 == playerForm.info.lib_gender_id}  required />
                            <label htmlFor={`gender-female`} 
                            className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                <div className="block">
                                    <div className="w-full text-sm font-bold truncate">Female</div>
                                </div>
                            </label>
                        </li>
                    </ul>
                </div>
                <div className=' flex-1 mr-2'>
                    <label className='text-sm'>Phone No</label>
                    <input type='number' placeholder='Ex. 09748262912'
                            name='phone_no' onChange={handleChange} value={playerForm.info.phone_no}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
                <div className=' flex-1 mr-2'>
                    <label className='text-sm'>Student No</label>
                    <input type='number' placeholder='Ex. 2022400572'
                            name='student_no' onChange={handleChange} value={playerForm.player.student_no}
                            className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                </div>
            </div>
            {/* pos */}
            <div> 
                <label className='text-sm'>Select Position</label>
                <ul className="grid w-full gap-1 md:grid-cols-5">
                {position.map((item, index) => (
                    <li key={index}>
                        <input type="radio" id={`${item.acronym}-${item.id}`} name="position_id" value={item.id}  className="hidden peer" onClick={handleChange} required checked={item.id == playerForm.player.position_id}  />
                        <label htmlFor={`${item.acronym}-${item.id}`} 
                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                            <div className="block">
                                <div className="w-full text-sm font-bold truncate">{item.desc}</div>
                                
                            </div>
                        </label>
                    </li>
                ))}
                </ul>
            </div>
            <button type='submit' className='mt-5 bg-prc rounded-md py-2 w-full text-white'>Update Player</button>
        </form>
        </Box>
    </Modal>
    <tr className=' text-def-t font-semibold'>
        <td className='px-6 py-3'>{data.student_no}</td>
        <td className='px-6 py-3'>{data.basic_information_id.last_name}, {data.basic_information_id.first_name} {data.basic_information_id.middle_name.charAt(0).toUpperCase() + '.'}</td>
        <td className='px-6 py-3'>{data.basic_information_id.gender.desc}</td>
        <td className='px-6 py-3'>{data.position_id.desc}</td>
        <td className='px-6 py-3'>{data.status.desc}</td>
        <td className='px-6 py-3 flex flex-row justify-center'>
            <div className='bg-prc flex-none content-center px-1 rounded-md mr-2 cursor-pointer' onClick={handleDeleteModal}>
                <span className='icon-[ic--round-delete] bg-white w-6 h-6 mt-2 mx-2'></span>
            </div>
            {data.status.id == 2 ?(
                <div className='bg-green-400 flex-none content-center rounded-md px-1 cursor-pointer' onClick={handleModal}>
                    <span className='icon-[bx--edit] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div> 
            ):(
                <div className='bg-green-400 flex-none content-center rounded-md px-1 cursor-pointer' onClick={handleApporveModal}>
                    <span className='icon-[iconamoon--check-fill] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div>

            )}
        </td>
    </tr>
    </>
  )
}

export function BasicTeams( {data}) {
    // console.log(data)
    return (
      <tr className=' text-def-t font-semibold'>
          <td className='px-6 py-3'>{data.title}</td>
          <td className='px-6 py-3'>{data.sports_id.title}</td>
          <td className='px-6 py-3'>{data.coach_id.last_name}, {data.coach_id.first_name} {data.coach_id.middle_name.charAt(0).toUpperCase() + '.'}</td>
          <td className='px-6 py-3'>{data.players.length}</td>
      </tr>
    )
  }

