import React, { useEffect, useState } from 'react'
import { IncidentCard } from '../../../Components/Cards'
import axios from 'axios';
import { v1Basic } from '../../../Resource/api';

export default function Incident() {
  const [loading, setLoading] = useState(true);
  const [incident, setIncident] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(v1Basic.concat('incident'));
        setIncident(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); 
  }, []);
  return (
    <div className='px-3'>
        <p className=' text-def-t text-2xl font-bold  py-2'>
            Incidents
        </p>
        {incident.map((item, index) => (
          <IncidentCard data={item} key={index}/>
        ))}
    </div>
  )
}
