import axios from 'axios'
import React, { useState,useEffect, useContext } from 'react'
import { baseUrl, v1Basic } from '../../../Resource/api'
import Loading from '../../../Components/Loading'
import { Box, Modal } from '@mui/material'
import { Navigate, NavLink, useNavigate } from 'react-router-dom'
import { AppContext } from '../../../Context/AppContext'

export default function UserManagement() {
    const navigate =useNavigate()
    const [userList,setUserList] = useState()
    const [loading, setLoading]= useState(true)
    const [warningIsVisible, setWarningIsVissible]= useState(false)
    const {token ,role} = useContext(AppContext)
    const [apiResponse ,setApiRespnse] = useState()

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const handleModal = () => setModalIsOpen(!modalIsOpen)

    const [userForm, setUserForm] =useState({
        user: {
            basic_information_id: '0',
            lib_role_id: '1',
            email: '',
            password: '',
            confirmPassword: '',
        },
        info:{
            first_name: '',
            middle_name: '',
            last_name: '',
            phone_no: '',
            lib_gender_id: '1',
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(userForm.user.password != userForm.user.confirmPassword || userForm.user.password.length < 8){
            setWarningIsVissible(true)
        } else {
                const storeData = async () => {
                    try {
                        setLoading(true)
                        await axios.post(baseUrl.concat(`/auth/register`), userForm, {
                        headers: {
                        'Content-Type': 'application/json'
                        }})
                        handleModal()
                    } catch (error) {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            setApiRespnse(error.response.data.errors)
                            console.error('Error Response Data:', error.response.data);
                            console.error('Error Response Status:', error.response.status);
                            console.error('Error Response Headers:', error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.error('Error Request:', error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.error('Error Message:', error.message);
                        }
                        
                    } finally {
                        setLoading(false); 
                        navigate(0)
                    }
                }
                storeData()
                setWarningIsVissible(false)

        }
    }
    const handleChange = (e) => {
        const {name, value} = e.target
        if(name === "password" ||name === "email" ||name === "confirmPassword" ||name === "lib_role_id"){
            setUserForm((prevForm) =>({
                ...prevForm,
                user:{
                    ...prevForm.user,
                    [name]: value
                }
            }))
            
        }else {
            setUserForm((prevForm) =>({
                ...prevForm,
                info:{
                    ...prevForm.info,
                    [name]: value
                }
            }))
        }
        // console.log(userForm)
    }
    useEffect(() => {
        const fetchData = async () => {
            try{
                setLoading(true)
                const response = await axios.get(v1Basic.concat('user'))
                setUserList(response.data.data)
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); 
            }
        }
        fetchData()
    }, [])

    // console.log(userList) 
    return (
        <>
        {token==null && (<Navigate to={'/login'} replace={true}/>)}
        {role != 'Admin' && (<Navigate to={'/'} replace={true}/>)}
        {loading && (<div className='z-50'><Loading /></div>)}

        <div className='z-10'>
            <Modal open={modalIsOpen} onClose={handleModal}
                aria-labelledby="modal-title" aria-describedby="modal-description"
                className="flex justify-center items-center h-screen"
            >

                <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
                {loading && (<div className='z-50'><Loading /></div>)}
                <h3 id="modal-title" className='font-semibold text-xl'>Register User</h3>
                <p id="modal-description " className='mb-4'>This Popup is for registration of Users.</p>
                {warningIsVisible ? (
                    <div className='transition transform translate-x-4 -ml-4 mr-4 border-l-4 border-prc mb-2 text-prc font-normal bg-prc rounded-md bg-opacity-30 p-3 flex flex-row'>
                      <p className='font-bold mr-2'>WARNING: </p>Password is to weak or does not match.
                    </div>
                ):(<div></div>)}
                <form onSubmit={handleSubmit}>
                    <label className='text-md font-semibold'>Account Information</label>
                    <div className=' flex-1'>
                        <label className='text-sm'>E-mail</label>
                        <input type='text' placeholder='Ex. john_ramos12@gmail.com'
                                name='email' onChange={handleChange}
                                className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                        {apiResponse?.['user.email'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['user.email']}</div>)}

                    </div>
                    <div className=' flex-1'>
                        <label className='text-sm'>Password</label>
                        <input type='password' placeholder='Enter Password'
                                name='password' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                    <div className=' flex-1'>
                        <label className='text-sm'>Re-enter Password</label>
                        <input type='password' placeholder='Confirm Password'
                                name='confirmPassword' onChange={handleChange}
                                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                    </div>
                    <div className='mb-3'>
                        <label className='text-sm'>Role</label>
                        <ul className="grid w-auto gap-1 md:grid-cols-3">
                            <li>
                                <input type="radio" id={`role-admin`} name="lib_role_id" value={1}  className="hidden peer" defaultChecked onClick={handleChange} required />
                                <label htmlFor={`role-admin`} 
                                className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                    <div className="block">
                                        <div className="w-full text-sm font-bold truncate">Admin</div>
                                    </div>
                                </label>
                            </li>
                            <li>
                                <input type="radio" id={`role-organizer`} name="lib_role_id" value={2}  className="hidden peer" onClick={handleChange} required/>
                                <label htmlFor={`role-organizer`} 
                                className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                    <div className="block">
                                        <div className="w-full text-sm font-bold truncate">Organizer</div>
                                    </div>
                                </label>
                            </li>
                            <li>
                                <input type="radio" id={`role-representative`} name="lib_role_id" value={3}  className="hidden peer" onClick={handleChange}  required />
                                <label htmlFor={`role-representative`} 
                                className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                    <div className="block">
                                        <div className="w-full text-sm font-bold truncate">College Representative</div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <label className='text-md font-semibold'>Basic Information</label>
                    <div className='  flex'>
                        <div className=' flex-1 mr-2'>
                            <label className='text-sm'>First Name</label>
                            <input type='text' placeholder='Ex. John'
                                    name='first_name' onChange={handleChange}
                                    className='w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                        {apiResponse?.['info.first_name'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.first_name']}</div>)}

                        </div>
                        <div className=' flex-1 mr-2'>
                            <label className='text-sm'>Last Name</label>
                            <input type='text' placeholder='Ex. Ramos'
                                    name='last_name' onChange={handleChange}
                                    className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                            {apiResponse?.['info.last_name'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.last_name']}</div>)}

                        </div>
                        <div className=' flex-1'>
                            <label className='text-sm'>Middle Name</label>
                            <input type='text' placeholder='Ex. Cruz'
                                    name='middle_name' onChange={handleChange}
                                    className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                            {apiResponse?.['info.middle_name'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.middle_name']}</div>)}

                        </div>
                        
                    </div>
                    <div className='  flex'>
                        <div className='flex-none mr-2'>
                            <label className='text-sm'>Gender</label>
                            <ul className="grid w-auto gap-1 md:grid-cols-2">
                                <li>
                                    <input type="radio" id={`gender-male`} name="lib_gender_id" value={1}  className="hidden peer" defaultChecked onClick={handleChange} required />
                                    <label htmlFor={`gender-male`} 
                                    className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                        <div className="block">
                                            <div className="w-full text-sm font-bold truncate">Male</div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id={`gender-female`} name="lib_gender_id" value={2}  className="hidden peer" onClick={handleChange}  required />
                                    <label htmlFor={`gender-female`} 
                                    className="inline-flex items-center justify-between w-full px-5 py-3 text-gray-500 bg-white  rounded-lg cursor-pointer dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-white dark:bg-prc dark:hover:bg-src dark:hover:bg-opacity-50">                           
                                        <div className="block">
                                            <div className="w-full text-sm font-bold truncate">Female</div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                            {apiResponse?.['info.lib_gender_id'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.lib_gender_id']}</div>)}
                            
                        </div>
                        <div className=' flex-1'>
                            <label className='text-sm'>Phone No</label>
                            <input type='number' placeholder='Ex. 09748262912'
                                    name='phone_no' onChange={handleChange}
                                    className=' w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                            {apiResponse?.['info.phone_no'] &&(<div className='text-sm text-red-800 text-opacity-60'>{apiResponse?.['info.phone_no']}</div>)}

                        </div>
                        
                    </div>
                    <button type='submit' className='mt-5 bg-prc rounded-md py-2 w-full text-white'>Sign Up</button>
                </form>
                </Box>
            </Modal>
            <div className=' m-2 mt-5 flex'>
            <div className='flex-1'>
                <div className='bg-cont flex-col flex   rounded-lg'>
                    <div className='flex-1 m-3 mb-8'>
                        <div className='flex'>
                        <div className=' flex-1 '>
                            <div className=' flex'>
                            <input type='text' placeholder="Find User..."
                                    className='bg-transparent p-2 border-2 rounded-l-md flex-1' />
                            <div className='p-3 flex-none bg-prc rounded-r-md'>
                                <span className='icon-[majesticons--search] bg-white w-5 h-5 mt-2 mx-2'></span>
                            </div>
                            <div className=' px-10 ml-20 text-center content-center flex-none bg-prc cursor-pointer text-white rounded-md' onClick={handleModal}>
                                Register User
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <table>
                        <thead className='bg-prc text-white text-left'>
                            <tr>
                                <th className='px-6 py-3'>Full Name</th>
                                <th className='px-6 py-3'>E-mail</th>
                                <th className='px-6 py-3'>Role</th>
                                <th className='px-6 py-3'>Contact No.</th>
                                <th className='px-6 py-3'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList?.map((item, index) => (
                                <UserInformation key={index} data={item}/>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </div>
     
        </>

    )
}

export function PlayerInformation() {
  return (
    <tr className=' text-def-t font-semibold'>
        <td className='px-6 py-3'>2019300201</td>
        <td className='px-6 py-3'>Someone O. There</td>
        <td className='px-6 py-3'>Male</td>
        <td className='px-6 py-3'>Mid Lane</td>
        <td className='px-6 py-3 flex flex-row justify-center'>
            <div className='bg-prc flex-none content-center px-1 rounded-md mr-2'>
                <span className='icon-[ic--round-delete] bg-white w-6 h-6 mt-2 mx-2'></span>
            </div>
            <div className='bg-green-400 flex-none content-center rounded-md px-1'>
                <span className='icon-[bx--edit] bg-white w-6 h-6 mt-2 mx-2'></span>
            </div>
        </td>
    </tr>
  )
}

export function UserInformation( data ) {
    const basicInformation = data.data.basic_information

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const handleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen)

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();

        try{
          const response = await axios.delete(v1Basic.concat(`user/${data.data.id}`))
          console.log('Response:', response.data);
        } catch(error){
          console.log(error)
        };
        handleDeleteModal()
    }

    // console.log(data.data)
    return (
    <>
        <Modal open={deleteModalIsOpen} onClose={handleDeleteModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t ">
                <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>Remove User</h3>
                <div className='p-6'>
                    <div id="modal-description " className='mb-4'>Are you sure you want to remove this user<p className='font-bold'>{basicInformation.first_name} {basicInformation.last_name}</p></div>
                    <form onSubmit={handleDeleteSubmit}>
                        <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Delete</button>
                    </form>
                </div>
            </Box>
        </Modal>
        <tr className=' text-def-t font-semibold'>
            <td className='px-6 py-3'>{basicInformation.first_name} {basicInformation.last_name}</td>
            <td className='px-6 py-3'>{data.data.email}</td>
            <td className='px-6 py-3'>{data.data.role.desc}</td>
            <td className='px-6 py-3'>{basicInformation.phone_no}</td>
            <td className='px-6 py-3 flex flex-row justify-center'>
                <div className= ' cursor-pointer bg-prc flex-none content-center px-1 rounded-md mr-2' onClick={handleDeleteModal}>
                    <span className='icon-[ic--round-delete] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div>
                <NavLink to={`${data.data.id}`} className='bg-green-400 flex-none content-center rounded-md px-1'>
                    <span className='icon-[bx--edit] bg-white w-6 h-6 mt-2 mx-2'></span>
                </NavLink>
            </td>
        </tr>
    </>
    )
  }

