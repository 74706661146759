import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { baseUrl } from '../Resource/api'
import { Navigate, replace } from 'react-router-dom'

export const AppContext = createContext()

export default function AppProvider({ children }) {
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [user, setUser] = useState()
    const [role,setRole] = useState(localStorage.getItem('role'))

    async function getUser () {
        try{
            const response = await axios.get(baseUrl.concat('/user'), {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            setUser(response.data)
            localStorage.setItem('role', response.data.data.role.desc)
            setRole(response.data.data.role.desc)
        } catch(error){
            console.log("Error:". error)
            if(token){
                localStorage.removeItem('token')
                localStorage.removeItem('role')
            } 
        }
    }
    
    useEffect (() => {
        if(token){
            getUser()
        }
    }, [token])
    return (
        <>
            <AppContext.Provider value={{ token, setToken, user, setUser, role, setRole }}>
                {children}
            </AppContext.Provider>
        </>
    )
}
