import { Box, Modal } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { v1Basic } from '../Resource/api'
import { formatDate, formatTime } from '../Page/Admin/Schedule/Bracketing'

export default function MatchSummary({ isAdmin =null, item ,index}) {
    // console.log(data)
    return (
        <>

        <li key={index} className='ml-4'>
            <label className="border-x-4 shadow-sm border-prc inline-flex rounded-xl  flex-col items-center justify-between w-full p-5 text-gray-500 bg-prc bg-opacity-25 dark:hover:text-gray-prc  dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600  dark:text-prc dark:bg-white   ">                           
                <div className="w-full text-sm font-bold truncate flex">
                <div className='flex-1 content-center justify-center'>Match {item.match_no}</div>
                <div className='flex-1'></div>
                <div className=' flex-1 w-full text-center border-r-4 border-prc rounded-md bg-green-100 font-normal text-sm mt-2'>Winner: {item.winningTeam.title}</div>
                </div>
                <div className='flex w-full'>
                {item.date || item.time ? 
                (
                    <div className='flex-1 mr-2 text-xl'>
                    {formatDate(item.date)}
                    <div className='text-sm font-medium'> {formatTime(item.time)}</div>
                    </div>
                ) : (
                    <div className='flex-1 mr-2 text-xl'>
                    Unscheduled
                    <div className='text-sm'> N/A</div>
                    </div>
                )} 
                <div className=' flex  content-center '>

                    {item.firstTeam == null ? (<div className='content-center '> TBD </div>):( <div className='content-center '>{item.firstTeam.title}</div>)}
                    <div className='content-center  font-thin text-2xl mx-3'>/</div>
                    {item.secondTeam == null ? (<div className='content-center '> TBD </div>):( <div className='content-center '>{item.secondTeam.title}</div>)}
                </div>

                </div>
            </label>
        </li>

        </>
    )
}

export function MatchSchedule( {data}) {
    // console.log(data.secondTeam.logo)
    if(data.secondTeam == null || data.firstTeam == null){
        return <></>
    }
    return (
        <div className=' bg-white rounded-md mb-2 p-3'>
            <div className='flex'>
                
                <div className='  flex-none mr-2'>
                    <div className=' flex flex-row'>
                        <div className='mr-2'>
                            <img src={data.firstTeam.logo != null ? (data.firstTeam.logo):(require('../Resource/logo-one.png'))} className=' rounded-full h-12 w-12' alt='img'/>
                            <p className=' text-center font-extrabold text-lg text-def-t'>{data.firstTeam.title}</p>
                        </div>
                        <div>
                            <img src={data.secondTeam.logo != null ? (data.secondTeam.logo):(require('../Resource/logo-one.png'))} className=' rounded-full h-12 w-12' alt='img'/>
                            <p className=' text-center font-extrabold text-lg text-def-t'>{data.secondTeam.title}</p>
                        </div>
                    </div>
                </div>
                <div className=' bg-def-t w-0.5 opacity-70 rounded-full mr-2 flex-none'></div>
                <div className='  flex-1 content-center '>
                    <div className='flex flex-col text-def-t'>
                        <div className='font-bold flex-1'>
                            {formatTime(data.time)}
                        </div>
                        <div className='font-bold flex-1'>
                            {data.firstTeam.title}  vs {data.secondTeam.title}
                        </div>
                        <div className=' font-semibold flex-1'>
                            {data.sports.venue}
                        </div>
                    </div>
                </div>
                <div className=' content-center'>
                    <div className=' flex-none self-center text-right '>
                        {data.sports.title}

                    </div>
                    <div className='flex flex-none self-center text-right -mt-1 text-xl font-semibold'>
                        {data.referee_full_name}<div className=' ml-2 font-thin'>as Referee</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function IncidentCard({data}) {
    if(data.lib_incident_status_id.id == 1){
        return<div></div>
    }

    return (
        <div>
            <div className=' flex'>
                <div className='flex-none flex bg-prc mr-2 w-24 h-auto text-center'>
                    <div className='flex flex-col w-full content-center justify-center '>
                        <div className='flex-1 h-full'></div>
                        <p className='flex-none font-semibold text-xl min-h-24 justify-center text-white content-center'>
                            NEWS
                        </p>
                        <div className='flex-1 h-full'></div>

                    </div>
                </div>
                <div className='flex-1 content-center'>
                    <div className='flex flex-col text-def-t'>
                        <div className='font-bold text-2xl flex-1 max-w-48'>
                            {data.title}
                        </div>
                        <div className=' font-semibold flex-1 max-w-52 overflow-y'>
                            {data.desc}
                        </div>
                    </div>
                </div>
            </div>
            <div className=' bg-def-t h-0.5 rounded-md my-2'></div>
        </div>
    )
}

export function CollegeRank({counter, data}) {

    return (
    <div className=' bg-white rounded-md mb-2 p-3'>
        <div className='flex '>
            <div className=' text-white font-bold text-3xl w-24 h-auto bg-prc flex-none -m-3 mr-3 rounded-l-md text-center '>
                <p className='content-center h-full'>{counter}</p>
            </div>
            <div className='  flex-none mr-2 content-center'>
                <div className='  flex flex-row '>
                    <img src={data.logo != null ? (data.logo):(require('../Resource/logo-one.png'))} className=' rounded-full h-12 w-12' alt='img'/>

                </div>
            </div>
            <div className='  flex-1 content-center '>
                <div className='flex flex-col text-def-t'>
                    <div className='font-extrabold text-2xl flex-1'>
                        {data.acronym}
                    </div>
                    <div className=' font-semibold -mt-1 flex-1'>
                        {data.name}
                    </div>
                </div>
            </div>
            <div className='  flex-none content-center'>
                <div className=' flex flex-row'>
                    <div className='flex flex-col text-def-t text-center mr-4'>
                        <div className='font-semibold text-2xl flex-1'>
                            {data.gold}
                            
                        </div>
                        <div className=' font-extrabold flex-1'>
                            GOLD
                        </div>
                    </div>
                    <div className='flex flex-col text-def-t text-center mr-4'>
                        <div className='font-semibold text-2xl flex-1'>
                            {data.silver}

                        </div>
                        <div className=' font-extrabold flex-1'>
                            SILVER
                        </div>
                    </div>
                    {/* <div className='flex flex-col text-def-t text-center mr-4'>
                        <div className='font-semibold text-2xl flex-1'>
                            6
                        </div>
                        <div className=' font-extrabold flex-1'>
                            BRONZE
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
    )
}

export function SportsInformation({sportID ,currentURL, data}) {
    const baseRoute = currentURL;
    const newURL = `${baseRoute}/${sportID}`;
    return (
        <>
        <NavLink to={newURL} 
            className={({ isActive }) => {
                return `hover:bg-prc hover:text-white max-w-72 flex  p-3 pr-16 ${isActive ? 'text-white bg-prc bg-opacity-95' : 'text-def-t bg-white '}`;
            }}
        >
            <div className=' flex-none mr-2'>
                <img src={data.logo != null ? (data.logo):(require('../Resource/logo-one.png'))} className=' rounded-full h-12 w-12' alt='img'/>
                
            </div>
            <div className=' flex-1 content-center '>
                <div className=' flex flex-col'>
                    <div className=' flex-1 font-semibold text-lg'>
                        {data.title}
                    </div>
                    <div className=' flex-1 -mt-1 font-normal text-sm'>
                        {data.venue}
                    </div>
                </div>
            </div>
        </NavLink>
        </>
  )
}

export function CRUD({data, api}){
    const [collegeForm, setCollegeForm] = useState({
        title: data.title,
        acronym: data.acronym
    })

    const handleChange = (e) => {
        const {name, value} = e.target
        setCollegeForm({
            ...collegeForm,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try{
          const response = await axios.put(api.concat(`/${data.id}`), collegeForm, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          console.log('Response:', response.data);
        } catch(error){
          console.log(error)
        };
        handleAddModal()
        window.location.reload()

    }

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
    
        try{
          const response = await axios.delete(api.concat(`/${data.id}`))
          console.log('Response:', response.data);
        } catch(error){
          console.log(error)
        };
        handleDeleteModal()
        window.location.reload()

    }
      
    const [addModalIsOpen, setAddModalIsOpen] = useState(false)
    const handleAddModal = () => setAddModalIsOpen(!addModalIsOpen)

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const handleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen)
    return (
        <>
        <Modal open={addModalIsOpen} onClose={handleAddModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
            <h3 id="modal-title" className='font-semibold text-xl'>Update College</h3>
            <p id="modal-description " className='mb-4'>This Popup is for adding a new College.</p>
            <form onSubmit={handleSubmit}>
                <label className='text-sm'>College Name</label>
                <br></br>
                <input type='text' placeholder='Ex. College of Computer Studies'
                        name='title' onChange={handleChange} value={collegeForm.title}
                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                <br ></br>
                <label className='text-sm'>Acronym</label>
                <br></br>
                <input  type='text' placeholder='Ex. CCS'
                name='acronym' onChange={handleChange} value={collegeForm.acronym}
                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Update</button>
            </form>
            </Box>
        </Modal>
        <Modal open={deleteModalIsOpen} onClose={handleDeleteModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t ">
                <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>Delete College</h3>
                <div className='p-6'>
                    <p id="modal-description " className='mb-4'>Are you sure you want to delete <p className='font-bold'>{data.title} ({data.acronym})</p></p>
                    <form onSubmit={handleDeleteSubmit}>
                        <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Delete</button>
                    </form>
                </div>
            </Box>
        </Modal>
        <div className='flex mb-2'>
            <div className='flex-1 bg-white rounded-md'>
                <div className='flex flex-row'>
                    <div className=' p-2 py-3 flex-none content-center mr-1'>
                        <div className=' flex flex-row '>
                            <img src={data.logo != null ? (data.logo):(require('../Resource/logo-one.png'))} className=' rounded-full h-12 w-12' alt='img'/>
                        </div>
                    </div>
                    <div className=' flex-1 content-center'>
                        <div className='flex flex-col  '>
                            <div className=' flex-1 font-bold text-lg'>
                                {data.acronym}
                            </div>
                            <div className=' flex-1 -mt-1 text-sm'>
                                {data.title}
                            </div>
                        </div>
                    </div>
                <div className='bg-prc flex-none content-center px-4 cursor-pointer' onClick={handleDeleteModal}>
                    <span className='icon-[ic--round-delete] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div>
                <div className='bg-green-400 flex-none content-center rounded-r-md px-4 cursor-pointer' onClick={handleAddModal}>
                    <span className='icon-[bx--edit] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div>

                </div>
            </div>
        </div>
        </>
    )
}


export function CRUDSport({data ,api}){
    const navigate = useNavigate()
    const handleNav = () =>{
        navigate(`${data.id}`)
    }
    const [sportsForm, setSportsForm] = useState({
        title: data.title,
        venue: data.venue
    })

    const handleChange = (e) => {
        const {name, value} = e.target
        setSportsForm({
            ...sportsForm,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try{
          const response = await axios.put(api.concat(`/${data.id}`), sportsForm, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          console.log('Response:', response.data);
        } catch(error){
          console.log(error)
        };
        handleAddModal()
        window.location.reload()

    }

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
    
        try{
          const response = await axios.delete(api.concat(`/${data.id}`))
          console.log('Response:', response.data);
        } catch(error){
          console.log(error)
        };
        handleDeleteModal()
        window.location.reload()

    }
      
    const [addModalIsOpen, setAddModalIsOpen] = useState(false)
    const handleAddModal = () => setAddModalIsOpen(!addModalIsOpen)

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const handleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen)
    return (
        <>
        <Modal open={addModalIsOpen} onClose={handleAddModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
            <h3 id="modal-title" className='font-semibold text-xl'>Update College</h3>
            <p id="modal-description " className='mb-4'>This Popup is for adding a new College.</p>
            <form onSubmit={handleSubmit}>
                <label className='text-sm'>College Name</label>
                <br></br>
                <input type='text' placeholder='Ex. College of Computer Studies'
                        name='title' onChange={handleChange} value={sportsForm.title}
                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                <br ></br>
                <label className='text-sm'>Venue</label>
                <br></br>
                <input  type='text' placeholder='Ex. TSU San Isidro'
                name='venue' onChange={handleChange} value={sportsForm.venue}
                className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Update</button>
            </form>
            </Box>
        </Modal>
        <Modal open={deleteModalIsOpen} onClose={handleDeleteModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t ">
                <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>Delete College</h3>
                <div className='p-6'>
                    <p id="modal-description " className='mb-4'>Are you sure you want to delete <p className='font-bold'>{data.title} ({data.venue})</p></p>
                    <form onSubmit={handleDeleteSubmit}>
                        <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Delete</button>
                    </form>
                </div>
            </Box>
        </Modal>
        <div className='flex mb-2'>
            <div className='flex-1 bg-white rounded-md'>
                <div className='flex flex-row'>
                    <div className=' p-2 py-3 flex-none content-center mr-1'>
                        <div className=' flex flex-row '>
                            <img src={data.logo != null ? (data.logo):(require('../Resource/logo-one.png'))} className=' rounded-full h-12 w-12' alt='img'/>
                        </div>
                    </div>
                    <div className=' flex-1 content-center'>
                        <div className='flex flex-col  '>
                            <div className=' flex-1 font-bold text-lg'>
                                {data.title}
                            </div>
                            <div className=' flex-1 -mt-1 text-sm'>
                                {data.venue}
                            </div>
                        </div>
                    </div>
                <div className='bg-src flex-none content-center px-4  cursor-pointer' onClick={handleNav}>
                    <div className=' bg-white p-1 rounded-md font-semibold mx-2 text-src'>POS</div>
                </div>
                <div className='bg-prc flex-none content-center px-4  cursor-pointer' onClick={handleDeleteModal}>
                    <span className='icon-[ic--round-delete] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div>
                <div className='bg-green-400 flex-none content-center rounded-r-md px-4 cursor-pointer' onClick={handleAddModal}>
                    <span className='icon-[bx--edit] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div>

                </div>
            </div>
        </div>
        </>
    )
}

export function SelectTeam({teamID, data}){
    return (
        <NavLink to={`${data.id}`}
            className='flex mb-2 hover:scale-101 hover:shadow cursor-pointer'>
            <div className='flex-1 bg-white rounded-md'>
                <div className='flex flex-row'>
                    <div className=' p-2 py-3 flex-none content-center mr-1'>
                        <div className=' flex flex-row '>
                            <img src={data.logo != null ? (data.logo):(require('../Resource/logo-one.png'))} className=' rounded-full h-12 w-12' alt='img'/>
                        </div>
                    </div>
                    <div className=' flex-1 content-center'>
                        <div className='flex flex-col  '>
                            <div className=' flex-1 font-bold text-lg'>
                                {data.title ?  (<div> {data.title} </div>) : (<div> No Data</div>)}
                            </div>
                            <div className=' flex-1 -mt-1 text-sm'>
                                {data.sports_id.title ?  (<div> {data.sports_id.title} </div>) : (<div> No Data</div>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}

export function BasicTitleAndSub({data}){
    return (
        <>
        <NavLink
            className='flex mb-2 hover:scale-101 hover:shadow cursor-pointer min-w-72'>
            <div className='flex-1 bg-white rounded-md'>
                <div className='flex flex-row'>
                    <div className=' p-2 py-3 flex-none content-center mr-1'>
                        <div className=' flex flex-row '>
                            <img src={data.logo != null ? (data.logo):(require('../Resource/logo-one.png'))} className=' rounded-full h-12 w-12' alt='img'/>
                        </div>
                    </div>
                    <div className=' flex-1 content-center'>
                        <div className='flex flex-col  '>
                            <div className=' flex-1 font-bold text-lg'>
                                {data.acronym ? (<div> {data.acronym} </div>) : (<div> {data.title} </div>)}
                            </div>
                            <div className=' flex-1 -mt-1 text-sm'>
                                {data.acronym ? (<div> {data.title} </div>) : (<div> {data.venue} </div>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
        </>
    )
}

export function BracketTeamSched() {
  return (
    <div className='flex text-def-t bg-white p-3 my-2 rounded-md shadow-sm'>
        <div className=' flex-1 font-normal text-md'>
            10:00 AM
            <div className=' font-bold text-xl'>
                10 Sept. 2024
            </div>
        </div>
        <div className=' flex-none content-center'>
            <div className='flex'>
                <div className=' flex-none content-center mr-2'>
                name
                </div>
                <div className=' flex-none content-center'>
                    <img src={require('../Resource/logo-one.png')} className=' h-12 w-full'/>
                </div>
                <div className=' flex-none content-center mx-2 text-3xl font-thin'>
                    /
                </div>
                <div className=' flex-none content-center mr-2'>
                    <img src={require('../Resource/logo-one.png')} className=' h-12 w-full'/>
                </div>
                <div className=' flex-none content-center '>
                name
                </div>
            </div>
        </div>

    </div>
  )
}


export function CRUDPos({desc, id  ,sportsID}) {
    const api = v1Basic.concat(`position/${id}`)
    const [positionForm, setPositionForm] = useState({
        desc: desc,
        sports_id: sportsID
    })
    const handleChange = (e) => {
        const {name, value} = e.target
        setPositionForm({
            ...positionForm,
            [name]: value
        })
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try{
          const response = await axios.put(api, positionForm, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        //   console.log('Response:', response.data);
        } catch (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Error Response Data:', error.response.data);
              console.error('Error Response Status:', error.response.status);
              console.error('Error Response Headers:', error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.error('Error Request:', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error Message:', error.message);
            }
        }
        handleAddModal()
    }

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
    
        try{
          const response = await axios.delete(api)
          console.log('Response:', response.data);
        } catch(error){
          console.log(error)
        };
        handleDeleteModal()
    }
      
    const [addModalIsOpen, setAddModalIsOpen] = useState(false)
    const handleAddModal = () => setAddModalIsOpen(!addModalIsOpen)

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const handleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen)
    return (
        <>
        <Modal open={addModalIsOpen} onClose={handleAddModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
            <h3 id="modal-title" className='font-semibold text-xl'>Update Position</h3>
            <p id="modal-description " className='mb-4'>This Popup is for updatine the position.</p>
            <form onSubmit={handleSubmit}>
                <label className='text-sm'>Position Name</label>
                <br></br>
                <input type='text' placeholder='Ex. College of Computer Studies'
                        name='desc' onChange={handleChange} value={positionForm.desc}
                        className='mb-2 w-full p-2 rounded-md border-def-t border-opacity-5 border-2'/>
                
                <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Update</button>
            </form>
            </Box>
        </Modal>
        <Modal open={deleteModalIsOpen} onClose={handleDeleteModal}
            aria-labelledby="modal-title" aria-describedby="modal-description"
            className="flex justify-center items-center h-screen"
        >
            <Box className="bg-white rounded-lg shadow-lg text-def-t ">
                <h3 id="modal-title" className='font-semibold text-xl text-center bg-prc rounded-t-lg text-white py-3'>Delete Positon</h3>
                <div className='p-6'>
                    <p id="modal-description " className='mb-4'>Are you sure you want to delete <p className='font-bold'>{desc}</p></p>
                    <form onSubmit={handleDeleteSubmit}>
                        <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Delete</button>
                    </form>
                </div>
            </Box>
        </Modal>
        <tr className=' text-def-t font-semibold'>
            <td className='px-6 py-3'>{desc}</td>
            <td className='px-6 py-3 flex flex-row justify-center'>
                <div className='bg-prc flex-none content-center px-1 rounded-md mr-2 cursor-pointer' onClick={handleDeleteModal}>
                    <span className='icon-[ic--round-delete] bg-white w-6 h-6 mt-2 mx-2'></span>
                </div>
                <div className='bg-green-400 flex-none content-center rounded-md px-1' onClick={handleAddModal}>
                    <span className='icon-[bx--edit] bg-white w-6 h-6 mt-2 mx-2 cursor-pointer'></span>
                </div>
            </td>
        </tr>
        </>
    )
  }