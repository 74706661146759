import React from 'react'
import { Outlet } from 'react-router-dom';

export default function Home() {
    return(
        <>
        <div className="flex pr-3 ">
            <div className='flex-1'>

                <Outlet/>
            </div>
        </div>
        </>
    );
}
