import React, { useContext } from 'react'
import { AdminTopBar, SideBar } from '../../Components/TopBar'
import SportSchedule from '../Public/Schedule/SportSchedule'
import { NavLink, Outlet } from 'react-router-dom';
import { AppContext } from '../../Context/AppContext';

export default function Index() {
  const {token} = useContext(AppContext)
  // console.log(token)
  return (
    <>
    <AdminTopBar/>
  <div className=' flex'>
        <SideBar/>
        <div className='flex-1'>
            <Outlet/>
        </div>
    </div>
    </>
  )
}
