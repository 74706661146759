import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { baseUrl, v1Basic } from '../../../Resource/api';
import { checkTeam, formatDate, formatTime } from './Bracketing';
import { Box, Modal } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import Loading from '../../../Components/Loading';
import { AppContext } from '../../../Context/AppContext';

export default function Result() {

  const { sportID } = useParams();
  const {token, role } = useContext(AppContext)
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/v1/scheduled-match/${sportID}`);
        setSchedule(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [sportID]);

  return (
    <> 
    {token==null && (<Navigate to={'/login'} replace={true}/>)}
    {role == 'College Representative' &&(<Navigate to={'/login'} replace={true}/>)}
      {loading ? (
        <Loading />
      ) : (
        <>
            {!schedule || schedule.length == 0 ? (
            <>
            <div className='w-full h-screen text-prc content-center justify-center text-center font-extralight text-4xl'>No Scheduled Match</div>
            </> 
            ):(
            <>
            <div className='m-3 px-3 py-5 bg-white rounded-md -mr-3 font-bold text-2xl text-prc'>
              Results: {schedule[0]?.sports.title}
              <div className='bg-prc h-1 rounded-lg mb-5 mt-2 max-w-96'></div>
    
              <div className='font-normal text-sm'>
                Venue: {schedule[0]?.sports.venue}
              </div>
              <ul className="grid w-full gap-2 md:grid-cols-3 mt-2">
                {schedule.map((item, index) => (
                  <ScheduleResult index={index} item={item} key={index} />
                ))}
              </ul>
            </div>
            </>  
        )}
        </>
      )}
    </>
  );
}

export function ScheduleResult({ item, index }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [schedule, setSchedule] = useState({
    date: null,
    time: null,
    match_no: '',
    match_round: '',
    next_match_id: null,
    first_team_id: null,
    second_team_id: null,
    winner_team_id: null,
    sports_id: item.sports.id,
    lib_game_statuses_id: 1,
  });
  const [first, setFirst] = useState('TBD');
  const [second, setSecond] = useState('TBD');

  const handleModal = () => setModalIsOpen(!modalIsOpen);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${v1Basic}schedule/${item.id}`, schedule, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.log(error);
    }
    handleModal();
  };

  useEffect(() => {
    const firstTeam = checkTeam(item.firstTeam);
    const secondTeam = checkTeam(item.secondTeam);

    setFirst(firstTeam.title);
    setSecond(secondTeam.title);

    setSchedule(prev => ({
      ...prev,
      date: item.date,
      time: item.time,
      match_no: item.match_no,
      match_round: item.match_round,
      next_match_id: item.next_match_id,
      first_team_id: firstTeam.id,
      second_team_id: secondTeam.id,
      winner_team_id: null,
      sports_id: item.sports.id,
      lib_game_statuses_id: 1,
    }));
  }, [item]);

  const handleChange = (e) => {
    const { value } = e.target;
    setSchedule(prev => ({
      ...prev,
      winner_team_id: parseInt(value, 10)
    }));
  };

  return (
    <>
      <Modal open={modalIsOpen} onClose={handleModal}
        aria-labelledby="modal-title" aria-describedby="modal-description"
        className="flex justify-center items-center h-screen"
      >
        <Box className="bg-white rounded-lg shadow-lg text-def-t p-6 ">
          <h3 id="modal-title" className='font-semibold text-xl'>Select a Winner</h3>
          <p id="modal-description " className='mb-4'>This Popup is for choosing the match winner.</p>
          <div>
            Match {schedule.match_no}
            <div className='select-none flex rounded-xl my-2 mb-6 border-prc border-b-4 border-t-4 justify-center'>
              <div className="flex items-center w-full">
                <div className="flex items-center justify-center flex-1">
                  <input
                    type="radio"
                    id={`match-${first}`}
                    name="winner_team_id"
                    value={schedule.first_team_id}
                    className="hidden peer"
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor={`match-${first}`}
                    className=" shadow-sm peer-checked:bg-green-400 peer-checked:bg-opacity-90 peer-checked:text-white  inline-flex items-center justify-center w-full py-3 text-3xl font-bold  rounded-l-xl cursor-pointer hover:bg-prc hover:bg-opacity-10 dark:hover:bg-green-400 dark:hover:bg-opacity-30"
                  >
                    <div className="block text-right">
                      <div className="w-full">{first}</div>
                    </div>
                  </label>
                </div>
                <div className="text-xl text-center mx-3">vs</div>
                <div className="flex items-center justify-center flex-1">
                  <input
                    type="radio"
                    id={`match-${second}`}
                    name="winner_team_id"
                    value={schedule.second_team_id}
                    className="hidden peer"
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor={`match-${second}`}
                    className=" shadow-sm peer-checked:bg-green-400 peer-checked:bg-opacity-90 peer-checked:text-white  inline-flex items-center justify-center w-full py-3 text-3xl font-bold  rounded-r-xl cursor-pointer hover:bg-prc hover:bg-opacity-10 dark:hover:bg-green-400 dark:hover:bg-opacity-30"

                  >
                    <div className="block text-left">
                      <div className="w-full">{second}</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

          </div>
          <form onSubmit={handleSubmit} className='flex flex-col'>
            <button type='submit' className='mt-2 bg-prc rounded-md py-2 w-full text-white'>Update Winner</button>
          </form>
        </Box>
      </Modal>
      <li key={index} onClick={handleModal}>
        <label className="border-x-4 shadow-sm border-prc inline-flex rounded-xl flex-col items-center justify-between w-full p-5 text-gray-500 bg-white cursor-pointer dark:hover:text-gray-prc dark:peer-checked:text-prc dark:peer-checked:bg-src peer-checked:text-blue-600 hover:text-prc hover:bg-gray-100 dark:text-prc dark:bg-white hover:scale-101 dark:hover:bg-prc dark:hover:bg-opacity-10">
          <div className="w-full text-sm font-bold truncate">Match {item.match_no}</div>
          <div className='flex w-full'>
            {item.date || item.time ? (
              <div className='flex-1 mr-2 text-xl'>
                {formatDate(item.date)}
                <div className='text-sm font-medium'>{formatTime(item.time)}</div>
              </div>
            ) : (
              <div className='flex-1 mr-2 text-xl'>
                Unscheduled
                <div className='text-sm'>N/A</div>
              </div>
            )}
            <div className='flex content-center'>
              {item.firstTeam == null ? (<div className='content-center'>TBD</div>) : (<div className='content-center'>{item.firstTeam.title}</div>)}
              <div className='content-center font-thin text-2xl mx-3'>/</div>
              {item.secondTeam == null ? (<div className='content-center'>TBD</div>) : (<div className='content-center'>{item.secondTeam.title}</div>)}
            </div>
          </div>
        </label>
      </li>
    </>
  )
}
